import React from "react";
import '../../pages/css/style_home.css';
import logoimg from "../../pages/img/core-img/caindialogo_1.png";
import 'font-awesome/css/font-awesome.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavbarGet from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navbar() {
  return (
    <NavbarGet sticky="top" collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <NavbarGet.Brand href="/"><img src={logoimg} alt=""></img></NavbarGet.Brand>
        <NavbarGet.Toggle aria-controls="responsive-navbar-nav" />
        <NavbarGet.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          </Nav>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="services">Services</Nav.Link>
            <Nav.Link href="about">About Us</Nav.Link>
            {/* <Nav.Link href="why_us">Why Us</Nav.Link> */}
            <Nav.Link href="contact">Contact Us</Nav.Link>
          </Nav>
        </NavbarGet.Collapse>
      </Container>
    </NavbarGet>
  );
}

export default Navbar;