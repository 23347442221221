import axios from 'axios';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import bg_img_44 from "../../pages/img/bg-img/44.jpeg";
import core_img_icon_5 from "../../pages/img/core-img/icon-5.png";
import core_img_icon_6 from "../../pages/img/core-img/icon-6.png";
import core_img_icon_7 from "../../pages/img/core-img/icon-7.png";
import core_img_icon_8 from "../../pages/img/core-img/icon-8.png";
import mony_prod_service from "../../pages/img/product_services/money-product-services.jpg";
//img/core-img/icon-5.png

export default function CreateContactUs() {
  const navigate = useNavigate();

  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneno, setPhoneNo] = useState("")
  const [message, setMessage] = useState("")
  const [validationError,setValidationError] = useState({})


  const createContactUs = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('firstname', firstname)
    formData.append('fname', firstname)
    formData.append('lastname', lastname)
    formData.append('lname', lastname)
    formData.append('email', email)
    formData.append('phoneno', phoneno)
    formData.append('message', message)

    await axios.post(`https://finobotics.com/user/send_mail_enq`, formData).then(({data})=>{
      Swal.fire({
        icon:"success",
        text:data.message
      })
      navigate("/contact")
    }).catch(({response})=>{
        if(response)
        {
            if(response.status===422){
                setValidationError(response.data.errors)
            }else{
                Swal.fire({
                text:response.data.message,
                icon:"error"
                })
            }
        }
        else{
            if(!response)
            {
                Swal.fire({
                    text:"Error",
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    text:response.data.message,
                    icon:"error"
                })
            }
        }
    })
  }

  return (
    <>
      {/* <section className="breadcrumb-area bg-img bg-gradient-overlay jarallax" style={{ background: `url(${mony_prod_service})` }}>
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2 className="page-title">Contact</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="home">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    
      <section className="contact--us-area section-padding-100-0">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="contact-us-thumb mb-100">
                        <img src={bg_img_44} alt=""></img>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="contact_from_area mb-100 clearfix">
                        <div className="contact-heading">
                            <h4>Contact us</h4>
                        </div>
                        <div className="contact_form">
                            <form action="user/send_mail" method="post">
                                <div className="contact_input_area">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control mb-30" name="fname" id="contact_us_name" placeholder="Your Name*" required />
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control mb-30" name="lname" id="contact_us_lname" placeholder="Last Name*" value="None" required />
                                        
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control mb-30" name="email" id="contact_us_email" placeholder="E-mail" />
                                            </div>
                                        </div>
                                        
                                        <input type="hidden" className="form-control mb-30" name="mobile_no" id="contact_us_mobile_no" value="9999999999" placeholder="Your Number*" />

                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control mb-30" id="contact_us_message" cols="30" rows="6" placeholder="Message*" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn confer-btn" onClick={createContactUs}>Send Message <i className="zmdi zmdi-long-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className="map-area">
        <div className="row">
            <div className="col-6">
                <iframe src="https://maps.google.com/maps?width=250&amp;height=400&amp;hl=en&amp;q=Saral%20Heights,%20opp%20Radhey%20Skyline,%20Nana%20Chiloda,%20Ahmedabad,%20Gujarat%20Pin%20%E2%80%93%20382330+(My%20BusinL%20P%20C%20S%20&amp;%20Associates)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" aria-label="CA FIRM" allowFullScreen></iframe>
            </div>
            <div className="col-6">
            <iframe src="https://maps.google.com/maps?width=250&amp;height=400&amp;hl=en&amp;q=101,Vardhman%20Complex,%20Makerwali%20Road,%20Vaishali%20Nagar,%20Ajmer,%20Rajasthan+(Callp)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" aria-label="CA FIRM" allowFullScreen></iframe>
                {/* <iframe src="https://maps.google.com/maps?width=250&amp;height=400&amp;hl=en&amp;q=Shastri%20nagar,ajmer%20Ajmer+(Wealthkaka)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" aria-label="CA FIRM" allowFullScreen></iframe> */}
            </div>
            <script type='text/javascript' src='https://maps-generator.com/google-maps-authorization/script.js?id=834c495148bd981733be17e3ed94b9ca65c1a94a'></script>
        </div>
    </div>

    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="contact--info-area bg-boxshadow">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="single-contact--info text-center">
                                <div className="contact--info-icon">
                                    <img src={core_img_icon_5} alt="" />
                                </div>
                                <h4>Head office</h4>
                                <h5>
                                L P C S & Associates 
                                A-503, Saral Heights, opp Radhey Skyline, 
                                Nana Chiloda, Ahmedabad, Gujarat
                                Pin – 382330
                                </h5>
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-6">
                            <div className="single-contact--info text-center">
                                <div className="contact--info-icon">
                                    <img src={core_img_icon_5} alt="" />
                                </div>
                                
                                <h4>Branch office</h4>
                                <h5>
                                L P C S & Associates 
                                101,Vardhman Complex, Makerwali Road, Vaishali Nagar, Ajmer, Rajasthan
                                Pin-305001
                                </h5>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="contact--info-area bg-boxshadow">
                    <div className="row">
                        
                        <div className="col-12 col-md-4">
                            <div className="single-contact--info text-center">
                                <div className="contact--info-icon">
                                    <img src={core_img_icon_6} alt="" />
                                </div>
                                <h5>+91-8949460459</h5>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="single-contact--info text-center">
                                <div className="contact--info-icon">
                                    <img src={core_img_icon_7} alt="" />
                                </div>
                                <h5>ca@lpcsindia.com</h5>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="single-contact--info text-center">
                                <div className="contact--info-icon">
                                    <img src={core_img_icon_8} alt="" />
                                </div>
                                <h5>calpcsindia.com</h5>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}