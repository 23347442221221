import React from "react";
import bg_img_37 from "../pages/img/bg-img/37.jpg";

import car_purchase_icn from "./img/home-page/car-purchase-icn.png";
import child_education_planning_icon from "./img/home-page/child-education-planning-icon.png";
import emergancy_fund_icon from "./img/home-page/emergancy-fund-icon.png";
import fino_virtual_cfo_icon from "./img/home-page/fino_virtual_cfo_icon.png";
import house_purchase_icon from "./img/home-page/house-purchase-icon.png";
import tax_planning_icon from "./img/home-page/tax-planning-icon.png";

const Services = () => {
    return (
        <>
            {/* <section className="breadcrumb-area bg-img bg-gradient-overlay jarallax" style={{ background: `url(${bg_img_37})` }}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="page-title">Services</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="home">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="what-we-offer-area section-padding-110-70">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                                <h4>Our Services</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                                <h3>Registrations, Licences & Incorporations </h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Incorporation</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Partnership Firm </li>
                                    <li>Sole-Proprietorship Firm</li>
                                    <li>Producer Company (Farmers)</li>
                                    <li>One Person Company (OPC)</li>
                                    <li>Limited Liability Partnership (LLP)</li>
                                    <li>Private Limited Company</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Non-Profit Organisations</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Trust & Society Registration</li>
                                    <li>80G Certificate - Tax Free Donations</li>
                                    <li>12A Registration</li>
                                    <li>Section 8 Company Registration</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">License</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Import Export Code (IEC)</li>
                                    <li>Udyog Aadhaar / MSME / SSI</li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Trademark & IP Rights</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Trademark Registration</li>
                                    <li>Patent Registration</li>
                                    <li>Copyright Application</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                                <h3>Taxation, Compliances & Allied Services</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Goods & Service Tax (GST)</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Register For GST Number</li>
                                    <li>Filling of GST Return</li>
                                    <li>Reply for GST Notice</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Income Tax (IT)</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>TDS Return Filling & Form 16 Issuance</li>
                                    <li>Income Tax Return Filling</li>
                                    <li>Tax Auditing </li>
                                    <li>Reply to IT Notice</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Business Compliances</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Annual Filings of Company</li>
                                    <li>Annual Filings of LLP</li>
                                    <li>Any Other Business Compliances</li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="single-we-offer-content-custom-service text-center wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="services_custom">Other business-related services</h4>
                                <ul className="services_custom" style={{"display": "inline-block","text-align": "left"}}>
                                    <li>Accounting & Book-keeping</li>
                                    <li>Project Report</li>
                                    <li>Payroll Management</li>
                                    <li>Pitch Desk</li>
                                    <li>Fundraising</li>
                                    <li>CMA Report</li>
                                </ul>
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
            </section>
        </>
        
    );
};
 
export default Services;