import React from "react";
import '../pages/css/style_about.css';
import bg_img_44 from "../pages/img/bg-img/44.jpeg";
import bg_img_team_11 from "../pages/img/bg-img/team-1.jpeg";
import bg_img_team_1 from "../pages/img/bg-img/team_1.jpeg";
import bg_img_team_2 from "../pages/img/bg-img/team_2.jpeg";
import bg_img_team_3 from "../pages/img/bg-img/team_3.jpeg";
import bg_img_27 from "../pages/img/product_services/money-product-services.jpg";

const About = () => {
    return (
        <>
        {/* <section className="breadcrumb-area bg-img bg-gradient-overlay jarallax" style={{ background: `url(${bg_img_27})` }}>
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <h2 className="page-title">About Us</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="home">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <section className="about-us-countdown-area section-padding-100-0" id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">
                            <h3 className="wow fadeInUp" data-wow-delay="300ms">About Us</h3>
                            
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                            We are a full service Chartered Accountancy Firm with 5 Full Time Partners having varied skill sets. We have expertise across diverse sectors including Audit & Assurance, Direct Taxes (including Transfer Pricing), Indirect Taxes, Risk & Financial Advisory, Business Support / Outsourcing & Consulting.
Each practice area is led by a partner having in-depth knowledge and experience in the specific area supported by a highly motivated pool of professionals comprising of committed CA’s, Company Secretaries, Cost & Management Accountants, MBA’s, Engineers, Lawyers, Management and domain experts which enables us to provide multi-disciplinary services. We follow the concept of single point relationship of the client with a lead partner who draws on the expertise of the large pool of resources internally to provide innovative and holistic solutions. 
                            </p>
                        
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_44} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>


        </section>

        <section className="our-speaker-area section-padding-100-60">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                            <h4>Our Team</h4>
                        </div>
                    </div>
                </div>
                
                <div className="row align-items-center post-author-area d-flex align-items-center my-5">
                    
                <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_team_2} alt=""></img>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">  
                            
                            <h5>Jyoti Kabra- Partner</h5>
                            <span>(B. Com., F.C.A.)</span><span>(Membership No.: 432781)</span>
                            <p>A Chartered Accountant having working experience in MNCs and Indian conglomerates groups with more than 9 years of experience including:</p>

                            <p>•    Syndication of Funds from Banks & Institution</p>
                            <p>•    Trademark Advisory Services</p>
                            <p>•    Service Tax & Income Tax Matters</p>
                            <p>•    Company Law matters</p>
                            
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_team_11} alt=""></img>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">  
                            
                            <h5>Vinay Sharma- Partner</h5>
                            <span>(B. Com., F.C.A.)</span>
                            <span>(Membership No.: 441233)</span>
                            <p>A Chartered Accountant having working experience in financial institutions and Lending partners with more than 9 years of experience in various fields including:</p>

                            <p>•   Portfolio Management & Advisory Services</p>
                            <p>•   Working Capital Finance and liaison with Banks & Financial Institutions</p>
                            
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_team_1} alt=""></img>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">  
                            
                            <h5>Kalpesh Patel- Partner</h5>
                            <span>(B. Com., F.C.A.)</span>
                            <span>(Membership No.: 186284)</span>
                            <p>A founder and visionary Chartered Accountant having versatile working exposure for more than 10 years in various fields including:</p>

                            <p>•   Management Audits & System Implementation</p>
                            <p>•   Statutory & Tax Audits</p>
                            <p>•   Statutory, Concurrent Audit of Branches of Nationalized Banks</p> 
                            <p>•   Stock Audit of large Corporates on behalf of Banks</p>
                            <p>•   Viability & Feasibility Analysis for Projects
                            </p>
                        </div>
                    </div>

                    
                    

                    {/* <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_team_1} alt=""></img>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">  
                            
                            <h5>Vaibhav Lahoti - Founder Partner</h5>
                            <span>(B. Com., F.C.A, F.A.F.D (ICAI))</span>
                            <span>(Membership No.: 451405)</span>
                            <p>A Chartered Accountant having working experience in financial institute and Investment Firms with more than 9 years of experience in various fields including:</p>

                            <p>•   Statutory & Tax Audits </p>
                            <p>•   Statutory, Concurrent Audit of Branches of Nationalized Banks</p>
                            <p>•   Stock Audit of large Corporates on behalf of Banks</p> 
                            
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-thumb mb-80 wow fadeInUp" data-wow-delay="300ms">
                            <img src={bg_img_team_3} alt=""></img>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="about-content-text mb-80">  
                            
                            <h5>Karan Chauhan- Partner</h5>
                            <span>(B. Com., F.C.A. D.I.S.A (ICAI))</span>
                            <span>(Membership No.: 194803)</span>
                            <p>A founder Chartered Accountant having working experience in Institutional firms  with more than 9 years of experience in various fields including:</p>

                            <p>•   Statutory & Tax Audits of Corporate & Non-Corporate Clients</p> 
                            <p>•   Internal Audits</p>
                            <p>•   Company Law Matters</p>
                            
                        </div>
                    </div> */}
                    
                    
                    
                    
                    
                </div>
            </div>
        </section>
        </>
    );
};
 
export default About;