import { default as React, useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import './css/index-style.css';
//import $ from 'jquery';
//import indexScript from "./js/index_script.js";
import client_img_1 from "./img/client-img/1.jpg";
import client_img_10 from "./img/client-img/10.jpeg";
import client_img_2 from "./img/client-img/2.jpg";
import client_img_3 from "./img/client-img/3.jpg";
import client_img_4 from "./img/client-img/4.jpg";
import client_img_5 from "./img/client-img/5.jpg";
import client_img_6 from "./img/client-img/6.jpg";
import client_img_7 from "./img/client-img/7.jpg";
import client_img_8 from "./img/client-img/8.jpg";
import client_img_9 from "./img/client-img/9.jpg";
import homeimgone from "./img/home-page/home_page_img.jpg";
import car_purchase_icn from "./img/home-page/car-purchase-icn.png";
import child_education_planning_icon from "./img/home-page/child-education-planning-icon.png";
import emergancy_fund_icon from "./img/home-page/emergancy-fund-icon.png";
import audit from "./img/home-page/audit.png";
import house_purchase_icon from "./img/home-page/house-purchase-icon.png";
import tax_planning_icon from "./img/home-page/tax-planning-icon.png";
import homeimgnine from "./img/product_services/9.jpg";
import homeimgtwo from "./img/product_services/2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import brand_img_1 from "./img/brand/1.jpg";
import brand_img_2 from "./img/brand/2.jpeg";
import brand_img_3 from "./img/brand/3.jpeg";
import brand_img_4 from "./img/brand/4.jpeg";
import brand_img_5 from "./img/brand/5.jpeg";
import brand_img_6 from "./img/brand/6.jpeg";
import brand_img_7 from "./img/brand/7.jpeg";
import brand_img_8 from "./img/brand/8.jpeg";


//import "./js/axios_submit_form.js";

import $ from 'jquery'; 
import swal from 'sweetalert';

var $body = $("body");
var crnt_url = window.location.href;
var crnt_parts = crnt_url.split("/");
var url_redirect = crnt_parts[crnt_parts.length-1];
    function submitContactFormUsr() {
        $.ajax({
            type: 'POST',
            url: 'https://finobotics.com/user/send_mail_enq',
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin":"*"
            },
            crossDomain: true,
                beforeSend: function(xhr){
                    xhr.withCredentials = true;
            },
            data: {
            fname:$("#fname").val(),
            lname:$("#fname").val(),
            email:$("#email").val(),
            mobile_no:'6000000000',
            message:'Book Free Call',
            //url_redirect:$("#url_redirect").val()
            },
            success: function(response) {
                
                swal({
                        icon: "success",
                        title: "Successfully Send!",
                        //text:"Successfully Send",
                        showConfirmButton: true,
                        confirmButtonText: "Cerrar",
                        backdrop:true,
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        closeOnConfirm: false
                        }). then(function(result){
                        window.location = crnt_url;
                    });
                //window.location.href = crnt_url;
            },
         error:function(err) {
            swal({
                icon: "success",//"error",
                title: "Successfully Send!",//"Failed!",
                //text:"Successfully Send",
                showConfirmButton: true,
                confirmButtonText: "Cerrar",
                backdrop:true,
                allowOutsideClick: false,
                closeOnClickOutside: false,
                closeOnConfirm: false
                }). then(function(result){
                window.location = crnt_url;
            });
         }
        });
    };
function Home() {
    const [hovered, setHovered] = useState(false);
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
      };

      
     
    return (
    <>    
        <section className="welcome-area">
            <div className="welcome-slides owl-carousel">
            <div className="single-welcome-slide bg-img jarallax" style={{ background: `url(${homeimgone})` }}>
                        
                            <div className="container h-100">
                                <div className="row h-100 align-items-center">
                                    <div className="row">
                                        <div className="col-12 col-md-6  col-xl-6">
                                            <div className="welcome-text text-center">
                                                <h1 data-animation="fadeInUp" data-delay="300ms">The Last Team you will ever need for your  </h1>
                                                <h3>Accounts | Finance | Taxation</h3>
                                                <span>Register, Manage and Grow your business with us.</span>
                                                    {/* <a href="javascript:void(0)" className="btn confer-btn enquire_now_btn_comn">Request a Demo<i className="zmdi zmdi-long-arrow-right"></i></a> */}
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6  col-xl-6">
                                            <div className="welcome-text text-center">
                                                {/* <h2 data-animation="fadeInUp" data-delay="300ms">Your ACCOUNTS is <br/>in safe hands.</h2> */}
                                                
                                                <div class="login">
                                                    <h3>Start your journey with us</h3>
                                                    <form metho="post">
                                                        <input type="text" id="fname" name="fname" placeholder="Name" required />
                                                        <input type="email" id="email" name="email" placeholder="Email" required pattern="[^ @]*@[^ @]*\.[a-zA-Z]{2,}"/>
  
                                                        {/* <input type="submit"/> */}
                                                        <a onClick={submitContactFormUsr} className="btn confer-btn enquire_now_btn_comn">book a free call<i className="zmdi zmdi-long-arrow-right"></i></a>
 
                                                    </form>
                                                </div>
                                                    {/* <a href="javascript:void(0)" className="btn confer-btn enquire_now_btn_comn">book a free call<i className="zmdi zmdi-long-arrow-right"></i></a> */}
                                                
                                            </div>
                                        </div>
                                        
                                        <div className="custom_brand">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3>Trusted by 50+ brands</h3>
                                                    <span>Worked with exceptional Founders and Investors</span>
                                                </div>
                                            </div>
                                            <div className="row">
                        

                                                <div className="col-12">
                                                    <div className="our-client-area wow fadeInUp" data-wow-delay="300ms">
                                                        <div className="client-area owl-carousel">
                                                        <Slider {...settings}>
                                                        <div>
                                                        <img src={brand_img_1} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_2} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_3} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_4} alt=""></img>
                                                        </div>
                                                        
                                                        <div>
                                                        <img src={brand_img_5} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_6} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_7} alt=""></img>
                                                        </div>
                                                        <div>
                                                        <img src={brand_img_8} alt=""></img>
                                                        </div>
                                                    </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <h2 data-animation="fadeInUp" data-delay="300ms">Your ACCOUNTS is <br/>in safe hands.</h2> */}
                        </div>   
            </div>
        </section>
        
        <section className="what-we-offer-area section-padding-110-70">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                            <h4>YOU PLAN YOU BUSINESS. WE PLAN YOUR DELIVERABLES.</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                    <a href="services">
                        <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                            <div className="offer-icon">
                                <img src={emergancy_fund_icon} alt=""></img>
                            </div>
                            <h5>Statutory & Corporate Audit</h5>
                            
                        </div>
                    </a>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4">
                        <a href="services">
                            <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="offer-icon">
                                    <img src={tax_planning_icon} alt=""></img>
                                </div>
                                <h5>Income Tax Advisory Services</h5>
                                
                            </div>
                        </a>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4">
                        <a href="services">
                            <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="offer-icon">
                                    <img src={child_education_planning_icon} alt=""></img>
                                </div>
                                <h5>GST Advisory Services</h5>
                                
                            </div>
                        </a>
                    </div>

                    
                </div>



                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <a href="services">
                            <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="offer-icon">
                                    <img src={car_purchase_icn} alt=""></img>
                                </div>
                                <h5>Company Law Advisory Services</h5>
                                
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-12 col-md-6 col-xl-4">
                        <a href="services">
                            <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="offer-icon">
                                    <img src={house_purchase_icon} alt=""></img>
                                </div>
                                <h5>Financial Advisory Services to Corporates</h5>
                                
                            </div>
                        </a>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4">
                        <a href="services">
                            <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="offer-icon">
                                    <img src={audit} alt=""></img>
                                </div>
                                <h5>Internal, Management Audits & System Implementation</h5>
                                
                            </div>
                        </a>
                    </div>


                    
                </div>
            </div>
        </section>

        <section className="what-we-offer-area section-padding-0-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                            <h4>Our Realizations</h4>
                        </div>
                    </div>
                </div>
                <div id="projectFacts" className="sectionclassName">
                    <div className="fullWidth eight columns">
                        <div className="projectFactsWrap ">
                        {hovered}
                            <div className="item wow fadeInUpBig animated animated counter" style={{"visibility": "visible"}}  onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                                <i className="fa fa-briefcase"></i>
                                
                                <p id="number1" className="number">
                                <ReactSimpleCounter end={1000} duration={2000} onComplete={() => {  }} />
                                +
                                </p>
                                <span></span>
                                <p>Assignments</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated counter" data-number="25" style={{"visibility": "visible"}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                                <i className="fa fa-industry"></i>
                                <p id="number2" className="number">
                                <ReactSimpleCounter end={25} duration={14000} onComplete={() => console.log('Animation 2 completed')} />
                                +
                                </p>
                                <span></span>
                                <p>Industries</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated" data-number="12" style={{"visibility": "visible"}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                                <i className="fa fa-rocket"></i>
                                <p id="number3" className="number">
                                <ReactSimpleCounter end={12} duration={13000} onComplete={() => console.log('Animation 3 completed')} />
                                +
                                </p>
                                <span></span>
                                <p>Services</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated" data-number="40" style={{"visibility": "visible"}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                                
                                {/* <i className="fa fa-smile-o"></i> */}
                                <i className="fa fa-users"></i>
                                {/* {hovered} */}
                                <p id="number4" className="number">
                                <ReactSimpleCounter end={100} duration={2000} onComplete={() => console.log('Animation 4 completed')} />
                                +
                                </p>
                                <span></span>
                                <p>Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="our-sponsor-client-area section-padding-30-30">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
                            <h4>OUR EXPERTISE</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    

                    <div className="col-12">
                        <div className="our-client-area wow fadeInUp" data-wow-delay="300ms">
                            <div className="client-area owl-carousel">
                            <Slider {...settings}>
                            <div>
                            <img src={client_img_1} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_2} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_3} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_4} alt=""></img>
                            </div>
                            
                            <div>
                            <img src={client_img_5} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_6} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_7} alt=""></img>
                            </div>
                            <div>
                            <img src={client_img_8} alt=""></img>
                            </div>
                        </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <div className="fix-invest-now-btn">
            <a href="javascript:void(0)" id="invest_now_btn_health_ins" className="btn confer-btn enquire_now_btn_comn">Enquire Now<i className="zmdi zmdi-long-arrow-right"></i></a>
        </div> */}
        
    </>
    );
};

const ReactSimpleCounter = ({ end, duration, onComplete }) => {
    const [count, setCount] = useState(0);
  
    useEffect(() => {
      const startTime = Date.now();
      const endTime = startTime + duration;
  
      const updateCount = () => {
        const now = Date.now();
        const timePassed = now - startTime;
        const progress = Math.min(1, timePassed / duration);
        const currentCount = Math.ceil(progress * end);
        setCount(currentCount);
  
        if (now < endTime) {
          requestAnimationFrame(updateCount);
        } else {
          setCount(end);
          if (onComplete) onComplete();
        }
      };
  
      updateCount();
  
      // Cleanup function
      return () => {
        setCount(0);
      };
    }, [end, duration, onComplete]);
  
    // return <span>{count}</span>;
    return count;
  };
 
export default Home;