import { FloatingWhatsApp } from '@carlos8a/react-whatsapp-floating-button';
import logoimg from "../pages/img/core-img/caindialogo_1_old.png";

const Whatsappmsg = () => {
    return (
      <div>
        <FloatingWhatsApp
          phoneNumber='+918949460459' // Required
          accountName='LPCS & Associates | Chartered Accountants' // Optional
          avatar={logoimg} // Optional
          initialMessageByServer='Hi there! How can I assist you?' // Optional
          statusMessage='Available' // Optional
          placeholder='Write here...' // Optional
          allowEsc={true} // Optional
          // Explore all available props below
        />
      </div>
    );
  };
  
  export default Whatsappmsg;