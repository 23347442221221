import React from "react";
import project1img from "./img/project1.jpg";
import project2img from "./img/project2.jpg";
 
const Projects = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12">
                    <h1>Our Projects</h1>
                    <p>Take a look at the Design Portfolio of Maison to understand more about the work we do. Among our recent projects, you’ll find our Residential Community as well as the Commercial ones. We’re able to turn our sketches into a reality for our clients’ needs and requirements. Contact us today to receive more information from our team.</p>
                    <div className="image-container">
                        <img src={project1img} width="80%" height="20%" alt="arhitects"></img>
                    </div>
                    <div className="image-container">
                        <img src={project2img} width="80%" height="20%" alt="arhitects"></img>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Projects;