import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import "./pages/css/style.css";
//import Navbar from "react-bootstrap/Navbar";
import Navbar from "./components/Navbar";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";


import ContactUs from "./components/contactus/create.component";
import Home from "./pages";
import About from "./pages/about";
import OurTeam from "./pages/ourteam";
import Projects from "./pages/projects";
import Services from "./pages/services";
import Whyus from "./pages/why_us";

function App() {
  // return (<Router>
  //   <Navbar bg="primary">
  //     <Container>
  //       <Link to={"/"} className="navbar-brand text-white">
  //         Basic Crud App
  //       </Link>

  //       <Routes>
  //               <Route exact path="/" element={<Home />} />
  //               <Route path="/about" element={<About />} />
  //               <Route
  //                   path="/contact"
  //                   element={<Contact />}
  //               />
  //               <Route path="/services" element={<Services />} />
  //               <Route
  //                   path="/our-team"
  //                   element={<OurTeam />}
  //               />
  //           </Routes>
  //     </Container>
  //   </Navbar>

  //   <Container className="mt-5">
  //     <Row>
  //       <Col md={12}>
  //         <Routes>
  //           <Route path="/product/create" element={<CreateProduct />} />
  //           <Route path="/product/edit/:id" element={<EditProduct />} />
  //           <Route exact path='/' element={<ProductList />} />
  //         </Routes>
  //       </Col>
  //     </Row>
  //   </Container>
  // </Router>);

  return (
    
    <Router>
          <Navbar />
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route
                  path="/contact"
                  element={<ContactUs />}
              />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/why_us" element={<Whyus />} />
              <Route
                  path="/our-team"
                  element={<OurTeam />}
              />
          </Routes>
      </Router>
  );
}

export default App;
