import React from "react";
 
const OurTeam = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                    <h1>Our Team</h1>
                    <p>Architects is made up of a dedicated group of professional architects and designers committed to excellence. The passionate people who work here come from a variety of backgrounds and experiences, with their ideas shaping the direction of the company and ensuring quality and uniqueness.</p>
                </div>
            </div>
        </div>
    );
};
 
export default OurTeam;