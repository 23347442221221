import React from "react";
import bg_img_37 from "../pages/img/bg-img/37.jpg";

import car_purchase_icn from "./img/home-page/car-purchase-icn.png";
import child_education_planning_icon from "./img/home-page/child-education-planning-icon.png";
import emergancy_fund_icon from "./img/home-page/emergancy-fund-icon.png";
import fino_virtual_cfo_icon from "./img/home-page/fino_virtual_cfo_icon.png";
import house_purchase_icon from "./img/home-page/house-purchase-icon.png";
import tax_planning_icon from "./img/home-page/tax-planning-icon.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free'
import {library} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
library.add(far,fas,fab);

const Whyus = () => {
    return (
        <>
            {/* <section className="breadcrumb-area bg-img bg-gradient-overlay jarallax" style={{ background: `url(${bg_img_37})` }}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="page-title">Why Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="home">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Why Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="what-we-offer-area section-padding-110-70">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                                <h4>Why Us</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon='fas fa-road' />
                                    </div>
                                    <h5>Long Term Oriented</h5>
                                    
                                </div>
                            </a>
                        </div>
                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                        <FontAwesomeIcon icon="fas fa-shipping-fast" />
                                    </div>
                                    <h5>E-Xpress Service</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="fas fa-hand-holding-usd" />
                                    </div>
                                    <h5>Money Back Guarantee</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="far fa-credit-card" />
                                    </div>
                                    <h5>Value For Money</h5>
                                    
                                </div>
                            </a>
                        </div>

                        
                    </div>



                    <div className="row">
                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="fab fa-cc-paypal" />
                                    </div>
                                    <h5>Easy EMI</h5>
                                    
                                </div>
                            </a>
                        </div>
                        
                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="far fa-smile-beam" />
                                    </div>
                                    <h5>Assured Satisfaction</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="far fa-thumbs-up" />
                                    </div>
                                    <h5>Transparent Pricing</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-3 col-xl-3">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="far fa-clock" />
                                    </div>
                                    <h5>Meeting Deadlines</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="fas fa-user-tie" />
                                    </div>
                                    <h5>CA/CS Assistance Only</h5>
                                    
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                            <a href="services">
                                <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="offer-icon">
                                    <FontAwesomeIcon icon="fas fa-lock" />
                                    </div>
                                    <h5>Data Confidentiality</h5>
                                    
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
        
    );
};
 
export default Whyus;